import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { GLOBAL_SEARCH } from "../../../graphql/modules";
import { Link, useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Highlighter from "react-highlight-words";

const DrawerLeft = ({
  menuDrawerClassName,
  openMenuDrawer,
  wrapRefMenu,
  aboutCategory,
}) => {
  const [searchTxt, setSearchTxt] = useState("");

  const categoryList = useSelector((state) => state.categories);

  const { data: searchData } = useQuery(GLOBAL_SEARCH, {
    variables: {
      searchTxt,
    },
  });
  let resultOfProduct = [];
  const suggestProduct =
    searchData?.FetchGlobalSearchProduct?.products?.map(
      ({ name, _id, category, subcategory }) => {
        let productFromCategory = [];
        let productFromSubcategory = [];
        if (category.length) {
          productFromCategory = category?.map((ctPro) => ({
            name,
            _id,
            type: "category",
            ref: ctPro?._id,
            parentName: ctPro?.name,
          }));
        }
        if (subcategory.length) {
          productFromSubcategory = subcategory?.map((ctPro) => ({
            name,
            _id,
            type: "subcategory",
            ref: ctPro?._id,
            parentName: ctPro?.name,
          }));
        }
        resultOfProduct.push(...productFromSubcategory, ...productFromCategory);
        return { name, _id };
      }
    ) || [];
  const suggestSubcategory =
    searchData?.FetchGlobalSearchProduct?.subcategories || [];
  const suggestCategory =
    searchData?.FetchGlobalSearchProduct?.categories || [];

  const history = useHistory();

  useEffect(() => {
    setSearchTxt("");
  }, [menuDrawerClassName]);
  const onRedirect = (_id, isProduct = false, isCategory = false, ref) => {
    if (isCategory) {
      history.push(`/category/${_id}?type=categoryId`);
    } else if (isProduct) history.push(`/products/${_id}?ref=${ref}`);
    else history.push(`/category/${_id}?type=subcategoryId`);
  };

  useEffect(() => {
    const mt =
      (document.querySelector("#lempor_rasel")?.offsetHeight || 0) +
      document.querySelector(".navbar-area").offsetHeight;
    wrapRefMenu.current.style.setProperty("top", mt + `px`, "important");
  }, [menuDrawerClassName]);

  return (
    <section
      className={`leftdrawer ${menuDrawerClassName ? "overlay" : ""}`}
      id="drawer"
    >
      <div
        ref={wrapRefMenu}
        className={`inner-drawer ${menuDrawerClassName ? "" : ""}`}
        style={{ position: "fixed" }}
      >
        <nav
          className={`animated bounceInDown ${
            menuDrawerClassName ? "" : "d_n"
          }`}
          style={{ cursor: "auto" }}
        >
          {/* <!-- Mobile Search Bar --> */}

          {/* <!-- Mobile Search Bar End --> */}

          <div className="left-side-menubar">
            <div className="inner-left-side-menubar">
              <div className="mobile_search visibility_mobile_search">
                <div className="mobile-inner-search">
                  <form action="">
                    <div className="input-group">
                      <span
                        className="input-group-text mobile-search-input-text"
                        id="basic-addon1"
                      >
                        <i className="fas fa-search"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control mobile-search-control"
                        placeholder="Search chillfitrave.com"
                        value={searchTxt}
                        onChange={(e) => setSearchTxt(e.target.value)}
                      />
                      <span
                        className="input-group-text cancel_search"
                        id="basic-addon1"
                        onClick={() => setSearchTxt("")}
                      >
                        Cancel
                      </span>
                    </div>
                  </form>
                </div>
              </div>
              {searchTxt ? (
                <div className="mobile_search_area">
                  <div
                    className="inner_m_search_area"
                    style={{ maxHeight: "84vh", overflow: "auto" }}
                  >
                    {suggestSubcategory.map(({ name, _id }) => (
                      <div
                        onClick={() => onRedirect(_id, false, false)}
                        className="mb-2"
                      >
                        <Highlighter
                          key={_id}
                          highlightClassName="highlight-class"
                          searchWords={searchTxt.split(" ")}
                          // autoEscape={true}
                          textToHighlight={name}
                        />
                      </div>
                    ))}

                    {suggestCategory.map(({ name, _id }) => (
                      <div
                        onClick={() => onRedirect(_id, false, true)}
                        className="mb-2"
                      >
                        <Highlighter
                          key={_id}
                          highlightClassName="highlight-class"
                          searchWords={searchTxt.split(" ")}
                          textToHighlight={name}
                        />
                      </div>
                    ))}

                    {resultOfProduct.map(({ name, _id, ref, parentName }) => (
                      <div
                        onClick={() => onRedirect(_id, true, false, ref)}
                        key={ref}
                        className="mb-2"
                      >
                        <Highlighter
                          key={_id}
                          highlightClassName="highlight-class"
                          searchWords={searchTxt.split(" ")}
                          // autoEscape={true}
                          textToHighlight={name + " > " + parentName}
                        />
                      </div>
                    ))}

                    {suggestProduct.length === 0 &&
                      suggestSubcategory.length === 0 &&
                      suggestCategory?.length === 0 && (
                        <ul>
                          <li>
                            <p>
                              No results for <strong>{searchTxt}</strong>
                            </p>
                          </li>
                          <li>
                            <i className="fas fa-chevron-right"></i>
                          </li>
                        </ul>
                      )}
                  </div>
                </div>
              ) : (
                <div className="accordion" id="accordionExamplewewewe">
                  {categoryList.map((item, cidx) =>
                    item?.subcategoryGroups?.length > 0 ? (
                      <div className="accordion-item" key={item._id}>
                        <h2 className="accordion-header" id={item._id}>
                          <button
                            className="accordion-button accord-menu-btn collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapseOne12${item._id}`}
                            aria-expanded="false"
                            aria-controls={item._id}
                          >
                            <span className="menu-title">
                              {item?.isColored
                                ? [...item?.name].map((letter, idx) => (
                                    <span
                                      key={idx}
                                      style={{
                                        color: getRandomColor(cidx + idx),
                                      }}
                                    >
                                      {letter}
                                    </span>
                                  ))
                                : item?.name}
                            </span>
                          </button>
                        </h2>
                        <div
                          id={`collapseOne12${item._id}`}
                          className="accordion-collapse collapse"
                          aria-labelledby={item._id}
                          data-bs-parent="#accordionExamplewewewe"
                        >
                          <div className="accordion-body submenu-body">
                            <ul>
                              {item.subcategoryGroups?.map((grp) => (
                                <div key={grp?._id}>
                                  <li
                                    className="text-uppercase"
                                    style={{
                                      fontWeight: "bold",
                                      color: "#3d3d3d",
                                    }}
                                  >
                                    {grp?.name}
                                  </li>
                                  {grp?.subcategory?.map((subItem) => (
                                    <li
                                      key={subItem._id}
                                      onClick={openMenuDrawer}
                                    >
                                      <Link
                                        to={`/category/${subItem?._id}?type=subcategoryId`}
                                        className="d-flex"
                                      >
                                        <div className="list-style">
                                          <p className="hover-style">
                                            {subItem?.name}
                                          </p>
                                        </div>
                                      </Link>
                                    </li>
                                  ))}
                                </div>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="accordion-item"
                        style={{
                          padding: "10px",
                          fontWeight: "700",
                          textTransform: "uppercase",
                        }}
                        key={item?._id}
                      >
                        <Link
                          to={`/category/${item?._id}?type=categoryId`}
                          style={{ color: "#3d3d3d" }}
                        >
                          {item?.isColored
                            ? [...item?.name].map((letter, idx) => (
                                <span
                                  key={idx}
                                  style={{
                                    color: getRandomColor(cidx + idx),
                                  }}
                                >
                                  {letter}
                                </span>
                              ))
                            : item?.name}
                        </Link>
                      </div>
                    )
                  )}
                  {/* <div
                    className="accordion-item"
                    style={{
                      padding: "10px",
                      fontWeight: "700",
                      textTransform: "uppercase",
                    }}
                    // key={item?._id}
                  >
                    <Link to={`/about`} style={{ color: "#3d3d3d" }}>
                      About us
                    </Link>
                  </div> */}
                  {aboutCategory?.length > 0 && (
                    <div className="accordion-item">
                      <h2 className="accordion-header" id={"about_us"}>
                        <button
                          className="accordion-button accord-menu-btn collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapseOne12_about_us`}
                          aria-expanded="false"
                          aria-controls={"about_us"}
                        >
                          <span className="menu-title">About us</span>
                        </button>
                      </h2>
                      <div
                        id={`collapseOne12_about_us`}
                        className="accordion-collapse collapse"
                        aria-labelledby={"about_us"}
                        data-bs-parent="#collapseOne12_about_us"
                      >
                        <div className="accordion-body submenu-body">
                          <ul>
                            {aboutCategory?.map((grp) => (
                              <div key={grp?._id}>
                                <li
                                  className="text-uppercase"
                                  style={{
                                    fontWeight: "bold",
                                    color: "#3d3d3d",
                                  }}
                                >
                                  {grp?.name}
                                </li>
                                {grp?.aboutItems?.map((subItem) => (
                                  <li
                                    key={subItem._id}
                                    onClick={openMenuDrawer}
                                  >
                                    <Link
                                      to={`/about?menu=${subItem?._id}`}
                                      className="d-flex"
                                    >
                                      <div className="list-style">
                                        <p className="hover-style">
                                          {subItem?.name}
                                        </p>
                                      </div>
                                    </Link>
                                  </li>
                                ))}
                              </div>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </nav>
      </div>
    </section>
  );
};

export default DrawerLeft;

const getRandomColor = (startIndex) => {
  // startIndex++;
  let colorCodes = ["#4284F4", "#EA4336", "#FABD05", "#34A853", "#EA4336"];
  if (startIndex > colorCodes.length) {
    startIndex = startIndex - colorCodes.length;
  }
  return colorCodes[startIndex];
};
