import gql from "graphql-tag";

export const FETCH_FAQ = gql`
  query FetchFAQ($limit: Int, $offset: Int) {
    FetchFAQ(limit: $limit, offset: $offset) {
      code
      success
      message
      result {
        _id
        name
        faq {
          _id
          qua
          ans
          position
        }
      }
    }
  }
`;

export const FETCH_POLICY = gql`
  query FetchPrivacyAndPolicy($limit: Int, $offset: Int) {
    FetchPrivacyAndPolicy(limit: $limit, offset: $offset) {
      code
      success
      message
      result {
        _id
        title
        desc
      }
    }
  }
`;

export const FETCH_ABOUT_CONTACT = gql`
  query FetchDynamicText {
    FetchDynamicText {
      code
      success
      message
      orderNoteLabel
      about
      aboutImageOrVideo
      contactUs
      returnPageRightContent
      returnPageImageOrVideo
      reasonList
      storeCreditDescription
      originalPaymentDescription
      returnPageLeftContent
      ownShippingDescription
      chillfitShippingDescription
      chillfitShippingDetails
    }
  }
`;

export const FETCH_LEGAL_AND_TERM = gql`
  query FetchLegalTerm($limit: Int, $offset: Int) {
    FetchLegalTerm(limit: $limit, offset: $offset) {
      code
      success
      message
      result {
        _id
        name
        faq {
          _id
          qua
          ans
        }
      }
    }
  }
`;

export const FETCH_SINGLE_ABOUT_ITEM = gql`
  query FetchSingleAbout($id: ID) {
    FetchSingleAbout(id: $id) {
      code
      success
      message
      result {
        _id
        mediaLinkWeb
        mediaLinkMobile
        name
      }
    }
  }
`;
