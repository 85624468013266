import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Footer = ({
  openSubscribe,
  isInMaintenance,
  wrapButtonSubscriber,
  aboutCategory,
}) => {
  const [customCoockie, setCustomCoockie] = useState("");

  const handleCookies = () => {
    localStorage.setItem("customCoockie", "ok");
    setCustomCoockie("ok");
  };

  useEffect(() => {
    setCustomCoockie(localStorage.getItem("customCoockie"));
  }, []);

  return (
    <div>
      {!isInMaintenance && (
        <>
          <section className="contact-area">
            <div className="container_standard">
              <div className="contact-inner">
                <div className="row">
                  <div className="col-md-4 col_30">
                    <div className="contact-area-left">
                      <div className="contact-title">
                        <h5 className="text-uppercase">Contact Us</h5>
                      </div>
                      <div className="contact-content">
                        <p className="text-uppercase">
                          Email Us:{" "}
                          <a
                            href="mailto:help@chillfitrave.com"
                            className="text-uppercase"
                          >
                            help@chillfitrave.com
                          </a>
                        </p>
                        <p className="text-uppercase">
                          WE WILL RESPOND TO YOU IN ENGLISH
                        </p>
                        <p className="text-uppercase">
                          within 24hours, from monday to friday.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col_50">
                    <div className="contact-title">
                      <h5 className="text-center">
                        MORE TO THE BEAT OF CHILLFIT
                      </h5>
                    </div>
                    <div className="contact-content mt_3">
                      <p className="text-center mb-2">
                        <span className="text-uppercase">
                          Never miss out the latest trends and get updates
                        </span>
                      </p>
                      <div className="subscribe-form">
                        <div className="subscribe-btn">
                          <button
                            className="btn subscribe-btn"
                            onClick={openSubscribe}
                            ref={wrapButtonSubscriber}
                          >
                            Subscribe
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col_20">
                    <div className="follow-us">
                      <div className="follow-us-title">
                        <h5 className="text-uppercase">Follow Us</h5>
                      </div>
                      <div className="follow-us-content d-flex">
                        <span className="me-3">
                          <a
                            target="_blank"
                            href="https://www.instagram.com/chillfitrave/"
                          >
                            <i className="fab fa-instagram"></i>
                          </a>
                        </span>
                        <span>
                          <a
                            target="_blank"
                            href="https://www.tiktok.com/@chillfitrave"
                          >
                            <img src="/images/logo-tiktok.svg" width="18" />
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="footer">
            <div className="container_standard pad_zero">
              <div className="inner-footer">
                <div className="row">
                  <div className="col-md-3">
                    <div className="footer-services">
                      <div className="service-title">
                        <h5>Service</h5>
                      </div>
                      <ul className="footer-list">
                        <li>
                          <Link to="/faq?search=contact us">Contact Us</Link>
                        </li>
                        <li>
                          <Link to="/faq?search=customization">
                            Customization
                          </Link>
                        </li>
                        <li>
                          <Link to="/faq">FAQ</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="footer-services">
                      <div className="service-title">
                        <h5>Order</h5>
                      </div>
                      <ul className="footer-list">
                        <li>
                          <Link to="/return-order">Return Order</Link>
                        </li>
                        <li>
                          <Link to="/faq?search=payment">Payment</Link>
                        </li>
                        <li>
                          <Link to="/faq?search=shipping">Shipping</Link>
                        </li>
                        <li>
                          <Link to="/faq?search=track order">Track Order</Link>
                        </li>
                        <li>
                          <Link to="/faq?search=refund and exchange">
                            Refund & Exchange
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="footer-services">
                      <div className="service-title">
                        <h5>About Us</h5>
                      </div>
                      <ul className="footer-list">
                        {/* <li>
                          <p
                            style={{
                              fontWeight: "bold",
                              color: "#fff",
                              fontSize: "14px",
                              textTransform: "uppercase",
                            }}
                          >
                            About Us
                          </p>
                        </li> */}
                        {!!aboutCategory?.length &&
                          aboutCategory?.map(({ aboutItems, name }) => (
                            <>
                              {/* <li>
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    color: "#fff",
                                    fontSize: "14px",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {name}
                                </p>
                              </li> */}
                              {aboutItems?.map(({ name, _id }) => (
                                <li key={_id}>
                                  <Link
                                    to={`/about?menu=${_id}`}
                                    style={{
                                      // marginLeft: "10px",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    {name}
                                  </Link>
                                </li>
                              ))}
                            </>
                          ))}
                        <li>
                          <a
                            target="_blank"
                            href="https://www.instagram.com/chillfitrave/"
                          >
                            Follow us on Instagram
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="footer-services">
                      <div className="service-title">
                        <h5>Legal</h5>
                      </div>
                      <ul className="footer-list">
                        <li>
                          <Link to="/legal?search=term and conditions">
                            Term & Conditions
                          </Link>
                        </li>
                        <li>
                          <Link to="/legal?search=privacy and cookies">
                            Privacy & Cookies
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- Footer Main End --> */}

          {/* <!-- Footer Bottom --> */}
          <section className="footer-mobile-menu">
            <div className="mobile-footer-inner">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      style={{ padding: "1rem 1.25rem" }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      <span className="text-uppercase">Services</span>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li>
                          <Link
                            className="text-uppercase"
                            to="/faq?search=contact us"
                          >
                            Contact Us
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="text-uppercase"
                            to="/faq?search=customization"
                          >
                            Customization
                          </Link>
                        </li>
                        <li>
                          <Link className="text-uppercase" to="/faq">
                            FAQ
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      style={{ padding: "1rem 1.25rem" }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <span className="text-uppercase">Order</span>
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li>
                          <Link
                            className="text-uppercase"
                            to="/faq?search=payment"
                          >
                            Payment
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="text-uppercase"
                            to="/faq?search=shipping"
                          >
                            Shipping
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="text-uppercase"
                            to="/faq?search=track order"
                          >
                            Track Order
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="text-uppercase"
                            to="/faq?search=refund and exchange"
                          >
                            Refund & Exchange
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      style={{ padding: "1rem 1.25rem" }}
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <span className="text-uppercase">About Us</span>
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <ul>
                        {/* <li>
                          <p
                            style={{
                              fontWeight: "bold",
                              color: "#fff",
                              fontSize: "14px",
                              textTransform: "uppercase",
                            }}
                          >
                            About Us
                          </p>
                        </li> */}
                        {!!aboutCategory?.length &&
                          aboutCategory?.map(({ aboutItems, name }) => (
                            <>
                              {/* <li>
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    color: "#fff",
                                    fontSize: "14px",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {name}
                                </p>
                              </li> */}
                              {aboutItems?.map(({ name, _id }) => (
                                <li
                                  key={_id}
                                  // style={{
                                  //   marginLeft: "10px",
                                  // }}
                                >
                                  <Link
                                    to={`/about?menu=${_id}`}
                                    style={{
                                      // paddingLeft: "10px",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    {name}
                                  </Link>
                                </li>
                              ))}
                            </>
                          ))}
                        <li>
                          <a
                            style={{ color: "#fff", cursor: "pointer" }}
                            target="_blank"
                            href="https://www.instagram.com/chillfitrave/"
                            className="text-uppercase"
                          >
                            Follow us on Instagram
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      style={{ padding: "1rem 1.25rem" }}
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <span className="text-uppercase">Legal</span>
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li>
                          <Link
                            className="text-uppercase"
                            to="/legal?search=term and conditions"
                          >
                            Term & Conditions
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="text-uppercase"
                            to="/legal?search=privacy and cookies"
                          >
                            Privacy & Cookies
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      <section className="footer-bottom">
        <div className="container pad_zero">
          <div className="footer-copytight">
            <p className="">
              Copyright&nbsp;&copy;&nbsp;{new Date().getFullYear()} Chillfit
              Inc. All rights reserved.
            </p>
          </div>
        </div>
      </section>

      {!customCoockie && (
        <section className="cookies_g" style={{ background: "#3d3d3d" }}>
          <div className="inner_cookies">
            <div
              className="alert alert-warning alert-dismissible fade show"
              role="alert"
            >
              <strong className="cookie_title">
                Do you like cookies? <span role="img">🍪</span>
              </strong>{" "}
              <div className="cookies_mr">
                We use cookies to ensure you get the best experience on our
                website.
              </div>
              For more information about cookies and the use on our website,
              please consult our &nbsp;
              <Link
                to="/faq"
                style={{
                  color: "#2c2c2c",
                  textDecoration: "underline",
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                Cookie Policy
              </Link>
              .
              <div className="cookie_button">
                <button className="btn btn_cookies" onClick={handleCookies}>
                  Accept
                </button>
                <button
                  className="btn btn_cookies"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={handleCookies}
                >
                  Decline
                </button>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default Footer;
