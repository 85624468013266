import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { redirectToCheckOut, SHIPPING } from "../../store";
import { DropdownMenu } from "./Header/DropdownMenu";
import { getWithDefaultImage, svgIcon } from "../../util";
import { Carousel } from "antd";

const Header = ({
  openShoppingCart,
  shoppingClassName,
  menuDrawerClassName,
  openMenuDrawer,
  userDrawerClassName,
  openUserDrawer,
  openSearchBar,
  wrapButtonUser,
  wrapButtonShopping,
  wrapButtonMenu,
  isInMaintenance,
  aboutCategory,
}) => {
  const { items = [], preItems = [] } = useSelector((state) => state.cart);
  const redirectPage = useSelector((state) => state.checkoutPage);
  const location = useLocation();
  const history = useHistory();
  const categoryList = useSelector((state) => state?.categories);
  let controlslider = useRef();
  const [menuShowMode, setShowMode] = useState(false);

  const dispatch = useDispatch();
  const handleBack = () => {
    if (location.pathname.includes("/order-confirmation")) {
      history.push("/");
    } else if (redirectPage === SHIPPING) {
      history.push("/");
    } else {
      dispatch(redirectToCheckOut({ type: SHIPPING }));
    }
  };
  const goToShop = () => {
    history.push("/welcome-board?tab=orders");
  };

  const hideIcon = location.pathname.includes("/order-details");
  const styleOnPath =
    location.pathname === "/checkout" ||
    // location.pathname === "/stock-out" ||
    location.pathname.includes("/order-confirmation");

  let count = 0;
  items.map((item) => {
    count = count + item.quantity;
  });
  preItems.map((item) => {
    count = count + item.quantity;
  });

  const [submenu, setSubmenu] = useState([]);
  const [aboutSubmenu, setAboutSubmenu] = useState(aboutCategory || []);

  const setArray = (ar) => {
    setSubmenu(ar);
  };
  console.log("aboutSubmenu ======================> ", aboutSubmenu);
  const handleCloseDropDown = () => {
    document.getElementById("custom-dropdown").style.display = "none";
  };
  const handleHover = () => {
    document.getElementById("custom-dropdown").style.display = "block";
  };
  const handleClickDropItem = () => {
    document.getElementById("custom-dropdown").style.display = "none";
    setSubmenu([]);
  };

  const SampleNextArrow = (props) => {
    const { className } = props;
    return (
      <button
        className={className}
        style={{
          width: "35px",
          height: "35px",
          borderRadius: "50%",
          background: "#fefcf6",
          // opacity: "0.8",
          alignSelf: "center",
          border: "1px solid #d7d3ce",
          right: "-20px",
        }}
        onClick={() => controlslider.next()}
      >
        <img
          style={{
            transform: "rotate(180deg)",
            aspectRatio: "unset",
            width: "6px",
          }}
          className="arrow-img"
          alt=""
          src="/img/poster-left-arrow.svg"
        />
      </button>
    );
  };
  const SamplePrevArrow = (props) => {
    const { className } = props;
    return (
      <button
        className={className}
        style={{
          width: "35px",
          height: "35px",
          borderRadius: "50%",
          background: "#fefcf6",
          // opacity: "0.8",
          alignSelf: "center",
          border: "1px solid #d7d3ce",
          left: "-18px",
        }}
        onClick={(e) => {
          controlslider.prev();
          e.stopPropagation();
        }}
      >
        <img
          style={{ marginRight: "2px", aspectRatio: "unset", width: "6px" }}
          className="arrow-img"
          alt=""
          src="/img/poster-left-arrow.svg"
        />
      </button>
    );
  };
  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <section className="navbar-area sticky-top" style={{ zIndex: "1000000" }}>
      <div className="inner-navbar-area">
        <div
          className={`custom-dropdown w-100 ${
            submenu?.length > 0 || aboutSubmenu?.length > 0 ? "" : "d-none"
          }`}
          id="custom-dropdown"
          onMouseEnter={handleHover}
          onMouseLeave={handleCloseDropDown}
        >
          <div
            className="container"
            style={{
              maxHeight: "600px",
              overflowY: "auto",
              marginBottom: menuShowMode ? "15px" : "7px",
            }}
          >
            <div
              className={`${!menuShowMode && "d-flex"} justify-content-center`}
            >
              {submenu?.map((pr, idx) => (
                <div key={idx} className="me-3 submenu-header">
                  <ul
                    style={{
                      paddingTop: "0.5rem",
                      marginTop: "0",
                      marginBottom: "0",
                    }}
                  >
                    <h6
                      style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                      }}
                      className="mb-2 mt-2 text-uppercase"
                    >
                      {pr?.name}
                    </h6>
                    {menuShowMode ? (
                      <React.Fragment>
                        <Carousel
                          infinite={pr.subcategory?.length > 4}
                          dots={false}
                          className="menu_carousel"
                          slidesToScroll={1}
                          slidesToShow={4}
                          slidesPerRow={1}
                          {...settings}
                          // effect="fade"
                          arrows
                          ref={(node) => {
                            if (
                              node?.innerSlider &&
                              !controlslider?.innerSlider
                            ) {
                              controlslider = node;
                            }
                          }}
                          // style={{gap:'10px'}}
                        >
                          {pr.subcategory.map((sub) => (
                            <li key={sub?._id}>
                              <Link
                                to={`/category/${sub?._id}?type=subcategoryId`}
                                onClick={handleClickDropItem}
                              >
                                <img
                                  src={getWithDefaultImage(sub?.coverImage)}
                                  style={{
                                    width: "100%",
                                    aspectRatio: "5/7",
                                    objectFit: "cover",
                                    marginBottom: "8px",
                                  }}
                                  alt=""
                                />
                                <span className="list-style">
                                  <span className="hover-style">
                                    {sub?.name}
                                  </span>
                                </span>
                              </Link>
                            </li>
                          ))}
                        </Carousel>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {pr?.subcategory?.map((sub) => (
                          <li key={sub?._id} className="mb-2 mt-2">
                            <Link
                              to={`/category/${sub?._id}?type=subcategoryId`}
                              onClick={handleClickDropItem}
                            >
                              <span className="list-style">
                                <span className="hover-style menu-anime-gap">
                                  {sub?.name}
                                </span>
                              </span>
                            </Link>
                          </li>
                        ))}
                      </React.Fragment>
                    )}

                    {pr.length < 3 && (
                      <div
                        style={{ paddingBottom: `${(3 - pr.length) * 22}px` }}
                      ></div>
                    )}
                  </ul>
                </div>
              ))}

              {aboutSubmenu?.map((pr, idx) => (
                <div key={idx} className="me-3 submenu-header">
                  <ul
                    style={{
                      paddingTop: "0.5rem",
                      marginTop: "0",
                      marginBottom: "0",
                    }}
                  >
                    <h6
                      style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                      }}
                      className="mb-2 mt-2 text-uppercase"
                    >
                      {pr?.name}
                    </h6>

                    <React.Fragment>
                      {pr?.aboutItems?.map((sub) => (
                        <li key={sub?._id} className="mb-2 mt-2">
                          <Link
                            to={`/about?menu=${sub._id}`}
                            onClick={handleClickDropItem}
                          >
                            <span className="list-style">
                              <span className="hover-style menu-anime-gap">
                                {sub?.name}
                              </span>
                            </span>
                          </Link>
                        </li>
                      ))}
                    </React.Fragment>

                    {pr.length < 3 && (
                      <div
                        style={{ paddingBottom: `${(3 - pr.length) * 22}px` }}
                      ></div>
                    )}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="container_standard">
          <div
            className="d-flex justify-content-between"
            style={
              styleOnPath
                ? { position: "relative", minHeight: "54px" }
                : { minHeight: "54px" }
            }
          >
            <div className="" style={styleOnPath ? { zIndex: "99" } : {}}>
              {!isInMaintenance && (
                <div className="side-menu">
                  {styleOnPath || hideIcon ? (
                    <div className="return_tag navbar-icon d-flex align-items-center">
                      <button
                        style={{
                          background: "none",
                          border: "none",
                          color: "#fff",
                          fontSize: "16px",
                          padding: "0",
                        }}
                        onClick={hideIcon ? goToShop : handleBack}
                      >
                        <i
                          className="fas fa-arrow-left"
                          style={{ color: "#fff" }}
                        ></i>
                        {hideIcon ? (
                          <span className="return_to">
                            &nbsp; Return to orders
                          </span>
                        ) : (
                          <span className="return_to">
                            {location.pathname === "/stock-out" ? (
                              ""
                            ) : (
                              <React.Fragment>
                                {" "}
                                &nbsp;{" "}
                                {location.pathname.includes(
                                  "/order-confirmation"
                                )
                                  ? "Continue"
                                  : "Return to"}{" "}
                                {redirectPage === SHIPPING
                                  ? location.pathname.includes(
                                      "/order-confirmation"
                                    )
                                    ? "shopping"
                                    : "bags"
                                  : "shipping"}
                              </React.Fragment>
                            )}
                          </span>
                        )}
                      </button>
                    </div>
                  ) : (
                    <div className="menu-icon navbar-icon d-flex align-items-center">
                      <div className="main-logo d-none d-lg-block m-0">
                        <a href="/">{svgIcon}</a>
                      </div>
                      {menuDrawerClassName ? (
                        <img
                          ref={wrapButtonMenu}
                          onClick={openMenuDrawer}
                          src="/images/close.svg"
                          style={{ height: "12px" }}
                          className="d-lg-none"
                          alt=""
                        />
                      ) : (
                        <img
                          ref={wrapButtonMenu}
                          onClick={openMenuDrawer}
                          src="/images/sidemenu.svg"
                          style={{ height: "12px" }}
                          className="d-lg-none"
                          alt=""
                        />
                      )}
                    </div>
                  )}
                </div>
              )}
              {isInMaintenance && (
                <div className="side-menu">
                  <div className="menu-icon"></div>
                  <div className="menu-icon" />
                </div>
              )}
            </div>
            <div
              className=""
              style={
                styleOnPath
                  ? { position: "absolute", width: "100%", zIndex: "0" }
                  : {}
              }
            >
              {location.pathname.includes("/checkout") ||
              location.pathname.includes("/order-confirmation") ||
              hideIcon ||
              isInMaintenance ? (
                <div className="main-logo navbar-icon d-flex align-items-center justify-content-center">
                  <a href="/">{svgIcon}</a>
                </div>
              ) : (
                !isInMaintenance && (
                  <div className="d-flex align-items-center justify-content-around h-100">
                    <div className="d-none d-lg-flex align-items-center h-100">
                      {categoryList?.map((item, idx) => (
                        <DropdownMenu
                          key={item._id}
                          idx={idx}
                          item={item}
                          setArray={setArray}
                          setShowMode={setShowMode}
                          setAboutSubmenu={setAboutSubmenu}
                        />
                      ))}
                      {!!aboutCategory?.length > 0 && (
                        <div
                          className="menu-name"
                          id="drop-menu"
                          onMouseEnter={() => {
                            document.getElementById(
                              "custom-dropdown"
                            ).style.display = "block";
                            setSubmenu([]);
                            setAboutSubmenu(aboutCategory);
                          }}
                          onMouseLeave={() => {
                            document.getElementById(
                              "custom-dropdown"
                            ).style.display = "none";
                          }}
                        >
                          <Link
                            to={`#`}
                            className="menu-name"
                            style={{ textTransform: "uppercase" }}
                          >
                            <span
                              className="inner-class"
                              style={{ fontWeight: "600" }}
                            >
                              About Us
                            </span>
                          </Link>
                        </div>
                      )}
                    </div>
                    <div className="d-flex d-lg-none align-items-center navbar-icon">
                      <div className="main-logo">
                        <a href="/">{svgIcon}</a>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
            <div className="" style={{ zIndex: "99" }}>
              {!isInMaintenance && (
                <>
                  {!location.pathname.includes("/order-confirmation") &&
                    location.pathname !== "/checkout" &&
                    !hideIcon && (
                      <div className="right-option navbar-icon">
                        <ul
                          className="nav-menu-list d-flex align-items-center ps-0"
                          style={{ height: "100%" }}
                        >
                          <li className="nav-shopping ps-0 me-0">
                            <i
                              ref={wrapButtonShopping}
                              className={`fas fa-shopping-bag ${
                                shoppingClassName ? "fa-times" : ""
                              }`}
                              style={{ cursor: "pointer" }}
                              onClick={openShoppingCart}
                            ></i>
                            <div className="item-count">
                              {count > 0 && !shoppingClassName ? (
                                <span>{count}</span>
                              ) : (
                                ""
                              )}
                            </div>
                          </li>
                          <li
                            className="nav-search ps-0"
                            style={{ marginRight: "35px" }}
                          >
                            <i
                              onClick={openSearchBar}
                              className="fas fa-search"
                            ></i>
                          </li>
                          <li className="nav-user ps-0">
                            <i
                              ref={wrapButtonUser}
                              className={`fas fa-user ${
                                userDrawerClassName ? "fa-times" : ""
                              }`}
                              onClick={openUserDrawer}
                              style={{ cursor: "pointer" }}
                            ></i>
                          </li>
                        </ul>
                      </div>
                    )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
